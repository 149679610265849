import * as React from 'react'
import Layout from '../components/layout'
import {Row, Col, Container} from "react-bootstrap" 
import scarcities from '../images/thumbnail-scarcities.png'
import beyondAdvertising from '../images/thumbnail-beyond-advertising.png'
import sdlg from '../images/thumbnail-sdlg.png'
import bots from '../images/thumbnail-antidepress-bots.png'
import eat from '../images/thumbnail-eaternet.png'
import pitapat from '../images/thumbnail-pitapat.png'
import { Link } from "gatsby"

const IndexPage = () => {
  return (
    <Layout pageTitle="Home">
      <Container>
      <h1 className='intro'>A data-driven studio, focused on making new technology tangible and understandable.</h1>
      </Container>
      <hr/>
      <Container><p>Recent Projects</p></Container>
      <hr/>
      <div className='home'>
        <Container className='mt-5'>
        <Link to='/work/sdlg'>
          <Row className="align-items-center">
            <Col sm={4}>
              <Row>
                <h2 className='hometitles'>SDLG</h2>
              </Row>
              <Row>
                <h3 className='homedesription'>Generating logos with deep learning.</h3>
                <p>View →</p>
              </Row>
            </Col>
            <Col sm={8}>
            <img src={sdlg} alt="Thumbnail of Scalable Deep Learning Graphics"/>
            </Col>
          </Row>
          </Link>
        </Container>
      </div>
      <div className='home'>
        <Container className='mt-5'>
            <Link to='/work/antidepress-bots'>
            <Row className="align-items-center">
              <Col sm={4}>
                <Row>
                  <h2 className='hometitles'>Antidepress Bots</h2>
                </Row>
                <Row>
                  <h3 className='homedesription'>Bot-network against depressive content on social media.</h3>
                  <p>View →</p>
                </Row>
              </Col>
              <Col sm={8}>
                <img src={bots} alt="Thumbnail of Antidepress Bots"/>
              </Col>
            </Row>
            </Link>
        </Container>
      </div>
      <div className='home'>
        <Container className='mt-5'>
            <Link to='/work/beyond-advertising'>
            <Row className="align-items-center">
              <Col sm={4}>
                <Row>
                  <h2 className='hometitles'>Beyond Advertising</h2>
                </Row>
                <Row>
                  <h3 className='homedesription'>WDKA internship platform during the pandemic.</h3>
                  <p>View →</p>
                </Row>
              </Col>
              <Col sm={8}>
                <img src={beyondAdvertising} alt="Thumbnail Beyond Advertising"/>
              </Col>
            </Row>
            </Link>
        </Container>
      </div>
      <div className='home'>
        <Container className='mt-5'>
            <Link to='/work/pitapat'>
            <Row className="align-items-center">
              <Col sm={4}>
                <Row>
                  <h2 className='hometitles'>Pit.a.pat</h2>
                </Row>
                <Row>
                  <h3 className='homedesription'>Listening landscape created for the Cinekid MediaLab.</h3>
                  <p>View →</p>
                </Row>
              </Col>
              <Col sm={8}>
                <img src={pitapat} alt="Thumbnail of Pit.a.pat"/>
              </Col>
            </Row>
            </Link>
        </Container>
      </div>
      <div className='home'>
        <Container className='mt-5'>
            <Link to='/work/scarcities'>
            <Row className="align-items-center">
              <Col sm={4}>
                <Row>
                  <h2 className='hometitles'>Scarcities</h2>
                </Row>
                <Row>
                  <h3 className='homedesription'>Mapping the use of space with Artificial Intelligence.</h3>
                  <p>View →</p>
                </Row>
              </Col>
              <Col sm={8}>
                <img src={scarcities} alt="Thumbnail of Scarcities"/>
              </Col>
            </Row>
            </Link>
        </Container>
      </div>
      <div className='home'>
        <Container className='mt-5'>
            <Link to='/work/eaternet'>
            <Row className="align-items-center">
              <Col sm={4}>
                <Row>
                  <h2 className='hometitles'>Eaternet</h2>
                </Row>
                <Row>
                  <h3 className='homedesription'>Product design for the overconsumption of media + junkfood.</h3>
                  <p>View →</p>
                </Row>
              </Col>
              <Col sm={8}>
                <img src={eat} alt="Thumbnail of Eaternet"/>
              </Col>
            </Row>
            </Link>
        </Container>
      </div>
      <hr/>
      <Link to='/work'><Container><p>More Projects →</p></Container></Link>
      <hr/>
    </Layout>
  )
}

export default IndexPage